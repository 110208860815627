//ag grid (fancy table thing) imports
@use 'ag-grid-community/styles' as ag;

// framework component themes (styles tied to theme variables)
@use 'themes' as *;
@use './overrides' as *;
// framework component styles
@use '@nebular/theme/styles/globals' as *;
@use '@nebular/auth/styles/globals' as *;

// @use 'tailwindcss/base' as *;
// @use 'tailwindcss/components' as *;
// @use 'tailwindcss/utilities' as *;
// @tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,600,700&display=swap');
// @import './layout';

// install the framework and custom global styles
@include nb-install() {
  // framework global styles
  @include nb-theme-global();
  @include nb-auth-global();

  // @include ngx-layout();

  // @include nb-overrides();
  // ::-webkit-scrollbar-thumb {
  //   background: nb-theme(scrollbar-color) !important;
  //   border-radius: 0.5rem !important;
  // }
}
// Make it so the toastr overlay is above the dialogs
.toastr-overlay-container {
  z-index: 1041 !important;
}

@include ag.grid-styles(
  (
    theme: alpine,
  )
);

.ag-theme-alpine {
  // use theme parameters where possible
  // --suppress-css-var-overrides: true;
  --ag-foreground-color: var(--text-basic-color);
  --ag-background-color: var(--background-basic-color-1);
  --ag-header-background-color: var(--background-basic-color-4);
  --ag-control-panel-background-color: var(--background-basic-color-4);
  --ag-secondary-foreground-color: var(--text-hint-color);
  --ag-odd-row-background-color: var(--background-basic-color-2);
  --ag-borders: none;
  --ag-input-disabled-border-color: var(--color-basic-disabled-border);
  --ag-disabled-foreground-color: var(--text-disabled-color);
  --ag-input-disabled-background-color: var(--color-basic-disabled);
  --ag-modal-overlay-background-color: var(--color-primary-transparent-default);
  --ag-header-column-resize-handle-color: var(--color-basic-focus-border);
  --ag-chip-background-color: var(--text-disabled-color);
  --ag-border-color: var(--background-basic-color-2);
  --ag-row-border-color: var(--background-basic-color-4);
  --ag-header-foreground-color: var(--text-basic-color);

  .ag-header-cell {
    font-size: var(--text-caption-2-font-size);
  }
}

::-webkit-scrollbar {
  width: 0.5rem !important;
  height: 0.5rem !important;
}
::-webkit-scrollbar-thumb {
  background: var(--color-basic-focus-border) !important;
  border-radius: 0.5rem !important;
  border: 0.15rem solid transparent !important;
  background-clip: padding-box !important;
  transition-property: border !important;
  transition-duration: 0.2s !important;
}
::-webkit-scrollbar-thumb:hover {
  border: 0 !important;
}
::-webkit-scrollbar-track {
  background: var(--background-basic-color-3);
}

body {
  font-family: 'muller';
}
nb-layout .layout {
  font-family: 'muller';
  background-color: var(--background-basic-color-1) !important;
}

// For each heading level, set the top margin to 0 and the bottom margin to 0.5rem.
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: 'muller-bold';
}
p {
  margin-top: 0;
  margin-bottom: 0.75rem;
}
nb-select {
  button {
    min-width: 6rem;
  }
}

form {
  font-size: var(--text-label-font-size);
  font-weight: var(--text-label-font-weight);
  line-height: var(--text-label-line-height);
}

nb-spinner {
  z-index: 100 !important;
}

.strip-default-styles {
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

nb-sidebar {
  transition-duration: 100ms;
  // transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-property: transform, width;
  .main-container {
    position: relative !important;
    width: 100% !important;
  }
  .main-container-fixed {
    top: 0px !important;
  }
}

.expander-button {
  $t-delay-hover: 0.5s;
  $t-delay: $t-delay-hover * 5;
  $t-duration: 0.2s;
  padding: var(--icon-button-ghost-medium-padding) !important;
  transition-property: padding, margin !important;
  transition-duration: $t-duration !important;
  transition-timing-function: ease-in-out !important;
  transition-delay: $t-delay;
  span {
    font-size: 0;
    transition: font-size $t-duration ease-in-out;
    transition-delay: $t-delay;
  }

  nb-icon {
    margin-left: 0 !important;
    margin-right: 0 !important;
    transition: margin 0.2s ease-in-out;
    transition-delay: $t-delay;
  }

  &:hover {
    padding: var(--button-filled-medium-padding) !important;
    transition-delay: $t-delay-hover;
    span {
      font-size: var(--text-button-medium-font-size) !important;
      transition-delay: $t-delay-hover;
    }
    nb-icon.right-offset {
      margin-right: var(--button-medium-icon-offset) !important;
      transition-delay: $t-delay-hover;
    }
    nb-icon.left-offset {
      margin-left: var(--button-medium-icon-offset) !important;
      transition-delay: $t-delay-hover;
    }
  }
}

// These overrides make mat-button-toggle components look like nebular components
// Why? because some nebular components don't have enough functionality, so we use material components
.mat-button-toggle-overrides {
  .mat-button-toggle-button:focus {
    outline: none;
  }
  .mat-button-toggle-checked {
    background-color: nb-theme(color-primary-transparent-200) !important;
  }
  .mat-button-toggle-group {
    border: 1px solid nb-theme(color-primary-500);
  }
  .mat-button-toggle {
    border-color: nb-theme(color-primary-500) !important;
    background-color: nb-theme(color-primary-transparent-default);
  }
  .mat-button-toggle:focus {
    background-color: nb-theme(color-primary-transparent-focus);
  }
  .mat-button-toggle:hover {
    background-color: nb-theme(color-primary-transparent-hover);
  }
  .mat-icon,
  .mat-button-toggle-label-content {
    color: nb-theme(color-primary-500);
  }
}
.mat-button-toggle-overrides-filled {
  .mat-button-toggle-button:focus {
    outline: none;
  }
  .mat-button-toggle-checked {
    background-color: nb-theme(color-primary-500) !important;
    .mat-button-toggle-label-content {
      color: nb-theme(text-alternate-color) !important;
    }
  }
  .mat-button-toggle-group {
    border: 1px solid nb-theme(color-basic-300);
    height: 32px;
  }
  .mat-button-toggle {
    // border-color: nb-theme(color-primary-500) !important;
    background-color: nb-theme(color-basic-300);
  }

  .mat-button-toggle:hover,
  .mat-button-toggle:focus {
    background-color: nb-theme(color-primary-500);
    .mat-button-toggle-label-content {
      color: nb-theme(text-alternate-color) !important;
    }
  }
  .mat-icon,
  .mat-button-toggle-label-content {
    color: nb-theme(text-primary-color);
    font-weight: bold;
    line-height: 30px !important;
    font-size: 14px !important;
  }
  // .mat-icon:focus,
  // .mat-button-toggle-label-content:focus,
  // .mat-icon:focus,
  // .mat-button-toggle-label-content:focus {
  //   color: nb-theme(color-primary-500);
  // }
}

.mat-select-overrides {
  width: 100%;

  .mat-mdc-select {
    display: flex !important;
    border: 1px solid var(--color-primary-default-border) !important;
    border-radius: var(--border-radius) !important;
    padding: 0 16px !important;
  }
  .mat-mdc-select-value {
    color: var(--text-basic-color) !important;
    font-family: var(--font-family-primary) !important;
    font-size: var(--text-subtitle-font-size) !important;
    font-weight: var(--text-subtitle-font-weight) !important;
  }
  .mat-mdc-select-min-line {
    color: var(--text-basic-color) !important;
  }
  .mat-mdc-select-arrow {
    color: var(--text-hint-color) !important;
  }
}

.cdk-overlay-container .cdk-overlay-pane .mat-select-panel-overrides {
  background-color: var(--background-basic-color-1) !important;
  .mat-mdc-option {
    color: var(--text-basic-color) !important;
    font-weight: var(--text-subtitle-font-weight) !important;
    font-size: var(--text-subtitle-font-size) !important;
    line-height: var(--text-subtitle-line-height) !important;
    &:hover {
      background-color: var(--background-basic-color-2) !important;
    }
  }
}
.mat-pseudo-checkbox {
  color: var(--text-basic-color) !important;
}
.mdc-list-item__primary-text {
  color: var(--text-basic-color) !important;
}
.mat-pseudo-checkbox-checked.mat-pseudo-checkbox-full {
  background: var(--color-primary-default) !important;
}

// Cuts the text when it overflows and adds "..."
// Works only on single line text
.three-dots-overflow-boi {
  white-space: nowrap;
  overflow: hidden !important;
  text-overflow: ellipsis;
}

// basic card css for layout content
// .layout-content-card {
//   border-radius: 5px;
//   height: 100%;
//   width: 100%;
// }
.centered-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.centered-column {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.simple-row {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}
.simple-column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}
.no-scroll {
  overflow: hidden;
}
.no-border {
  border: none !important;
}
.no-padding {
  padding: 0 !important;
}
.dark-card {
  background-color: nb-theme(background-basic-color-4);
  box-shadow: nb-theme(shadow);
  border-radius: 0.5rem;
}

// Overrides for AngularGridster2

.gridster-column {
  border-color: nb-theme(color-primary-transparent-focus) !important;
  opacity: 0.4;
}

.gridster-row {
  border-color: nb-theme(color-primary-transparent-focus) !important;
  opacity: 0.4;
}

.gridster-component {
  background-color: nb-theme(background-basic-color-3) !important;
}

.gridster-preview {
  background-color: nb-theme(color-primary-transparent-300) !important;
}

// Pointer styles

.pointer {
  cursor: pointer;
}

.drag {
  cursor: move;
}

.table-open {
  cursor: context-menu;
}

.full-height {
  height: 100%;
}

.full-size {
  height: 100%;
  width: 100%;
}

.big-dialog {
  height: 90%;
  width: 80%;
  .cdk-visually-hidden {
    width: 99% !important;
    height: 99% !important;
  }

  nb-dialog-container {
    width: 100% !important;
    height: 100% !important;
  }
}

// Give an <a> tag the appearance of an active link, for accessibility neatness
.fake-link {
  text-decoration: underline !important;
  cursor: pointer;
  color: nb-theme(link-text-color) !important;
}

// Make an <a> tag look like regular text while still behaving like a normal link, with the hover and the linking and the stuff
.hidden-link {
  text-decoration: none;
  color: nb-theme(--text-basic-color);
}
.hidden-link:hover {
  color: nb-theme(--text-basic-color);
}

// Changes text when highlighted by HighlightPipe
.highlight-pipe-match {
  font-weight: bolder;
  color: #0088e2;
}

// Overrides the angular material button focus outline for color picker button
.color-picker-butt {
  button:focus {
    outline: 0 !important;
  }
}

// Styles for SVG narwhal logo
.narwhal-logo {
  .eye {
    stroke: var(--narwhal-logo-eye);
  }
  .horn {
    fill: var(--narwhal-logo-horn);
  }
  .outline {
    fill: var(--narwhal-logo-outline);
  }
  .belly {
    fill: var(--narwhal-logo-belly);
    stroke: var(--narwhal-logo-outline);
  }
  .back {
    fill: var(--narwhal-logo-back);
  }
}

// Used to display extra info about inputs
.input-popover {
  padding: 0.8rem 1rem 0.8rem 0.6rem;
  background-color: var(--background-basic-color-3);

  p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 4px 0;
  }

  .icon {
    margin-right: 8px;
    font-size: var(--text-paragraph-font-size);
    display: inline-flex;
    justify-content: center;
    align-items: center;
  }

  .danger {
    color: var(--form-field-addon-danger-text-color);
  }

  .success {
    color: var(--form-field-addon-success-text-color);
  }
}

// Hide the scroll bar
.hide-scroll-bar::-webkit-scrollbar {
  display: none;
}
.hide-scroll-bar {
  -ms-overflow-style: none;
  scrollbar-width: none;
}

// Disable text selection
.disable-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
  supported by Chrome, Edge, Opera and Firefox */
}

.draggable-cursor * {
  cursor: move !important; /* fallback: no `url()` support or images disabled */
  cursor: -webkit-grabbing !important; /* Chrome 1-21, Safari 4+ */
  cursor: -moz-grabbing !important; /* Firefox 1.5-26 */
  cursor: grabbing !important; /* W3C standards syntax, should come least */
}

.hint-text {
  color: var(--text-hint-color);
  font-size: var(--text-caption-2-font-size);
  font-weight: var(--text-caption-2-font-weight);
  line-height: var(--text-caption-2-line-height);
}

.subtitle {
  font-size: var(--text-subtitle-font-size);
  font-weight: var(--text-subtitle-font-weight);
  line-height: var(--text-subtitle-line-height);
}

.subtitle-2 {
  font-size: var(--text-subtitle-2-font-size);
  font-weight: var(--text-subtitle-2-font-weight);
  line-height: var(--text-subtitle-2-line-height);
}
