@use 'sass:selector';
@use '../../theming' as *;

@mixin nb-typography {
  #{if(&, '&', 'body')} {
    color: nb-theme(text-basic-color);
    font-family: nb-theme(text-paragraph-font-family);
    font-size: nb-theme(text-paragraph-font-size);
    font-weight: nb-theme(text-paragraph-font-weight);
    line-height: nb-theme(text-paragraph-line-height);
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    color: nb-theme(text-basic-color);
  }

  @each $size in (1, 2, 3, 4, 5, 6) {
    h#{$size},
    .h#{$size} {
      font-size: nb-theme(text-heading-#{$size}-font-size);
      font-family: nb-theme(text-heading-#{$size}-font-family);
      font-weight: nb-theme(text-heading-#{$size}-font-weight);
      line-height: nb-theme(text-heading-#{$size}-line-height);
    }
  }

  .subtitle,
  .subtitle-2 {
    color: nb-theme(text-basic-color);
  }

  .subtitle {
    font-family: nb-theme(text-subtitle-font-family);
    font-size: nb-theme(text-subtitle-font-size);
    font-weight: nb-theme(text-subtitle-font-weight);
    line-height: nb-theme(text-subtitle-line-height);
  }

  .subtitle-2 {
    font-family: nb-theme(text-subtitle-2-font-family);
    font-size: nb-theme(text-subtitle-2-font-size);
    font-weight: nb-theme(text-subtitle-2-font-weight);
    line-height: nb-theme(text-subtitle-2-line-height);
  }

  p,
  .paragraph {
    color: nb-theme(text-basic-color);
    font-family: nb-theme(text-paragraph-font-family);
    font-size: nb-theme(text-paragraph-font-size);
    font-weight: nb-theme(text-paragraph-font-weight);
    line-height: nb-theme(text-paragraph-line-height);
  }

  .paragraph-2 {
    color: nb-theme(text-basic-color);
    font-family: nb-theme(text-paragraph-2-font-family);
    font-size: nb-theme(text-paragraph-2-font-size);
    font-weight: nb-theme(text-paragraph-2-font-weight);
    line-height: nb-theme(text-paragraph-2-line-height);
  }

  a {
    color: nb-theme(link-text-color);
    text-decoration: nb-theme(link-text-decoration);
    font-size: inherit;
    font-style: inherit;
    font-weight: inherit;
    line-height: inherit;

    &:focus {
      color: nb-theme(link-text-focus-color);
    }

    &:hover {
      color: nb-theme(link-text-hover-color);
    }

    &.link-control,
    &.link-control:hover {
      color: nb-theme(text-control-color);
    }

    &.link-alternate,
    &.link-alternate:hover {
      color: nb-theme(text-alternate-color);
    }
  }

  .label {
    color: nb-theme(text-hint-color);
    font-family: nb-theme(text-label-font-family);
    font-size: nb-theme(text-label-font-size);
    font-weight: nb-theme(text-label-font-weight);
    line-height: nb-theme(text-label-line-height);
  }

  .caption {
    font-family: nb-theme(text-caption-font-family);
    font-size: nb-theme(text-caption-font-size);
    font-weight: nb-theme(text-caption-font-weight);
    line-height: nb-theme(text-caption-line-height);
  }

  .caption-2 {
    font-family: nb-theme(text-caption-2-font-family);
    font-size: nb-theme(text-caption-2-font-size);
    font-weight: nb-theme(text-caption-2-font-weight);
    line-height: nb-theme(text-caption-2-line-height);
  }

  .caption,
  .caption-2 {
    color: nb-theme(text-hint-color);

    @each $status in nb-get-core-statuses() {
      &.status-#{$status} {
        color: nb-theme(text-#{$status}-color);
      }
    }
  }

  li {
    color: nb-theme(list-item-text-color);
    font-family: nb-theme(list-item-font-family);
    font-size: nb-theme(list-item-font-size);
    font-weight: nb-theme(list-item-font-weight);
    line-height: nb-theme(list-item-line-height);
  }

  .text-alternate {
    color: nb-theme(text-alternate-color);
  }
  .text-disabled {
    color: nb-theme(text-disabled-color);
  }
  .text-hint {
    color: nb-theme(text-hint-color);
  }

  @each $status in nb-get-core-statuses() {
    .text-#{$status} {
      color: nb-theme(text-#{$status}-color);
    }
  }
}
